import { baseMixin } from './base';
import WhatWeLearned from '@/components/lessonPlanTemplate/classroom/WhatWeLearned';
import { DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION } from '@/constants/LessonPlan';
export const LabLessonMixin = {
  mixins: [baseMixin],
  components: {
    WhatWeLearned,
  },
  data() {
    return {
      Schema: {
        aim: {
          showKeywords: false,
          showResources: false,
          showRecap: true,
          maxResourcesLength: 0,
          resourceMaxLength: 0,
          label: {
            resourcesTitle: 'Recap',
          },
        },
        action: {
          show: false,
          showSummarySection: true,
        },
        actionProgram1: {
          show: true,
          configurableAction: {
            show: true,
            title: '',
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
            paragraphTextKey: '',
            items: 'actionProgram1.instructions',
            leafNode: 'instructions',
            teacherTipPath: 'actionProgram1.teacher_tip',
          },
          ...this.getParentActionMixin,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 10,
          },
        },
        actionProgram2: {
          show: true,
          configurableAction: {
            show: true,
            title: '',
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
            paragraphTextKey: '',
            items: 'actionProgram2.instructions',
            leafNode: 'instructions',
            teacherTipPath: 'actionProgram2.teacher_tip',
          },
          ...this.getParentActionMixin,
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 10,
          },
        },
        analysis: {
          show: false,
          showSummarySection: true,
          isSummaryOptional: false
        },
        analysisProgram1: {
          show: true,
          ...this.getParentActionMixin,
          configurableDiscussion: {
            keyQuestionPath: 'analysisProgram1.key_question',
            keyQuestionGuidePath: 'analysisProgram1.key_question_guide',
            items: 'analysisProgram1.instructions',
            explanations: 'analysisProgram1.explanations',
            leafNode: 'instructions',
            maxNoOfDiscussionSteps: 100,
          },
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 2,
          },
        },
        analysisProgram2: {
          show: true,
          ...this.getParentActionMixin,
          configurableDiscussion: {
            keyQuestionPath: 'analysisProgram2.key_question',
            keyQuestionGuidePath: 'analysisProgram2.key_question_guide',
            items: 'analysisProgram2.instructions',
            explanations: 'analysisProgram2.explanations',
            leafNode: 'instructions',
            maxNoOfDiscussionSteps: 100,
          },
          allowedTimes: DAILY_PLANS_LESSON_PLAN_TIMES_WITH_ADDITIONAL_VALUES_COLLECTION,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 2,
          },
        },
        application: {
          showSummarySection: true,
          showClasswork: false,
          showHomeWork: false,
          showClassWorkHomeWork: false,
          showDifferentiationTips: false,
          showClassworkTeacherTip: false,
          isNeedMoreHelpMandatory: false,
          isNeedExtraChallengeMandatory: false,
          morePractice: false,
          configurableMorePractice: {
            show: true,
            title: 'Homework',
            paragraphTextKey: 'application.lab_homework.paragraph_text',
            items: 'application.lab_homework',
            leafNode: 'lab_homework',
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
          },
          displayTime: false,
          displayTrailing: false,
          showLegacyComponentSwitcher: true,
          configurableMultiSlide: {
            show: true,
            maxSlidesCount: 1,
            slideLayoutCheckPath: 'application.lab_homework.instructions.0',
            subPath: 'application.lab_homework.instructions',
            subPathWithoutLeaf: 'application.lab_homework',
          },
          showSwitchableLayout: true,
        },
        assessment: {
          showSummarySection: false,
          canShowCheck: false,
          canShowClassroomExplanation: true,
          forceShowAMEFields: false,
          canShowOverall: false,
          configurableClassroomExplanation: {
            maxNoOfSteps: 100,
            maxNoOfSubStepsInStep: 100,
            maxSumOfStepsAndSubSteps: 500,
          },
        },
      },
    };
  },

  computed: {
    getParentActionMixin() {
      return baseMixin.data().Schema.action;
    },
    getParentAnalysisMixin() {
      return baseMixin.data().Schema.analysis;
    },
    canShowEvaluateAndGrade() {
      return false;
    },
  },
  methods: {
    prepareLessonPlanDataToSave() {
      return true;
    },
    validateLPData(lessonPlan) {
      console.log(lessonPlan);
      return true;
    },
    customValidations() {
      return true;
    },
  },
};
