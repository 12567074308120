export default {
  layout1: () => import('./generic/DynamicLayout.vue'),
  layout2: () => import('./generic/DynamicLayout.vue'),
  layout3: () => import('./generic/DynamicLayout.vue'),
  layout4: () => import('./generic/DynamicLayout.vue'),
  layout5: () => import('./generic/DynamicLayout.vue'),
  layout6: () => import('./generic/DynamicLayout.vue'),
  layout7: () => import('./generic/DynamicLayout.vue'),
  layout8: () => import('./generic/DynamicLayout.vue'),
};
