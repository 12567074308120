import Vue from 'vue';
import AssetTypes from '@/constants/AssetTypes';
import { getAssetUploadRequiredStatus, setNextArrayIndexWith } from '@/utils';
import { get, set, cloneDeep, unset } from 'lodash';
import lodash from 'lodash';
import { getFileTypeFromUrl } from '@/functions/global';

const { defaultState } = require('./state');

let componentHistory = {};

function setNestedObject(obj, path, value) {
  const pathArray = parsePath(path);
  pathArray.reduce((acc, key, idx) => {
    if (idx === pathArray.length - 1) {
      Vue.set(acc, key, value);
    } else {
      if (acc[key] == null) {
        const nextKey = pathArray[idx + 1];
        if (!isNaN(nextKey)) {
          Vue.set(acc, key, []);
        } else {
          Vue.set(acc, key, {});
        }
      }
    }

    return acc[key];
  }, obj);
}

function getNestedObject(obj, pathArray) {
  return pathArray.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj
  );
}

function getNestedObjectWithPath(obj, pathArray) {
  return pathArray.reduce((acc, key) => {
    if (acc && acc[key] === undefined) {
      Vue.set(acc, key, {});
    }
    return acc[key];
  }, obj);
}

function parsePath(path) {
  path = path.replace(/\[(\d+)\]/g, '.$1');
  return path.split('.');
}
const mutations = {
  updateSummaryText(state, payload) {
    let { key, text } = payload;

    if (key == 'assessment.overallSummary') {
      Vue.set(state.data.assessment, 'overallSummary', text);
    } else if (state.data[key]) {
      Vue.set(state.data[key], 'summary', text);
    }
  },
  updateBriefText(state, payload) {
    state.data.brief = payload.text;
  },
  addKeyword(state) {
    state.data.keywords.push({
      keyword: '',
      description: '',
    });
  },
  deleteKeyword(state, index) {
    state.data.keywords.splice(index, 1);
  },
  addResource(state) {
    state.data.aim.resources.push({
      brief: {
        text: '',
        assetId: '',
      },
      assetId: '',
      assetApproved: false,
      text: '',
    });
    state.signedUrls.aim.resources.push({
      signedUrl: '',
      brief: {
        signedUrl: '',
      },
    });
  },
  deleteResource(state, index) {
    state.data.aim.resources.splice(index, 1);
    state.signedUrls.aim.resources.splice(index, 1);
  },
  //////// Action Steps ////////
  addNewAssest(state, payload) {
    let { stepIndex, section } = payload;
    let newPath = '';
    if (stepIndex != null && !isNaN(stepIndex)) {
      newPath = `${section}[${stepIndex}]`;
    } else {
      newPath = section;
    }
    let instructionHash = {};
    if (section == 'action.instructions') {
      instructionHash = state.data.action.instructions[stepIndex] || {};
    } else if (section == 'application.more_practice') {
      if (!state.data.application.more_practice) {
        state.data.application.more_practice = [];
      } else if (!state.data.application.more_practice[stepIndex]) {
        state.data.application.more_practice[stepIndex] = {};
      }

      instructionHash = state.data.application.more_practice[stepIndex];
    } else if (section == 'analysis.instructions') {
      if (!state.data.analysis.instructions) {
        state.data.analysis.instructions = [];
      } else if (!state.data.analysis.instructions[stepIndex]) {
        state.data.analysis.instructions[stepIndex] = {};
      }

      instructionHash = state.data.analysis.instructions[stepIndex] || {};
    } else if (section == 'application.homework') {
      instructionHash = state.data.application.homework || {};
    } else if (section == 'analysis.listen_and_read') {
      instructionHash = state.data.analysis.listen_and_read || {};
    } else if (section != '') {
      instructionHash = get(state.data, newPath);
    } else {
      return true;
    }

    let assets = instructionHash.assets || [];
    let defaultAssetType = assets.length ? 'image' : null;
    assets.push({
      assetId: '',
      brief: {
        text: '',
        assetId: '',
      },
      assetApproved: false,
      type: defaultAssetType,
      placeholder: {
        assetId: '',
      },
    });

    if (section == 'action.instructions') {
      state.data.action.instructions[stepIndex].assets = assets;
    } else if (section == 'application.more_practice') {
      state.data.application.more_practice[stepIndex].assets = assets;
    } else if (section == 'analysis.instructions') {
      state.data.analysis.instructions[stepIndex].assets = assets;
    } else if (section == 'application.homework') {
      state.data.application.homework.assets = assets;
    } else if (section == 'analysis.listen_and_read') {
      state.data.analysis.listen_and_read.assets = assets;
    } else if (section != '') {
      Vue.set(instructionHash, 'assets', assets);
    }
  },
  addNewSignedUrlForAsset(state, payload) {
    let { stepIndex, section } = payload;
    let newPath = '';
    if (stepIndex != null && !isNaN(stepIndex)) {
      newPath = `${section}[${stepIndex}]`;
    } else {
      newPath = section;
    }
    let instructionHash = {};
    if (section == 'action.instructions') {
      instructionHash = state.signedUrls.action.instructions[stepIndex] || {};
    } else if (section == 'application.more_practice') {
      if (!state.signedUrls.application.more_practice) {
        state.signedUrls.application.more_practice = [];
      }
      if (!state.signedUrls.application.more_practice[stepIndex]) {
        state.signedUrls.application.more_practice[stepIndex] = {};
      }

      instructionHash = state.signedUrls.application.more_practice[stepIndex] || {};
    } else if (section == 'analysis.instructions') {
      if (!state.signedUrls.analysis.instructions) {
        state.signedUrls.analysis.instructions = [];
      }
      if (!state.signedUrls.analysis.instructions[stepIndex]) {
        state.signedUrls.analysis.instructions[stepIndex] = {};
      }

      instructionHash = state.signedUrls.analysis.instructions[stepIndex] || {};
    } else if (section == 'application.homework') {
      instructionHash = state.signedUrls.application.homework || {};
    } else if (section == 'analysis.listen_and_read') {
      instructionHash = state.signedUrls.analysis.listen_and_read || {};
    } else if (section != '') {
      instructionHash = get(state.signedUrls, newPath);
      if (instructionHash == undefined) {
        instructionHash = set(state.signedUrls, newPath, {});
      }
    } else {
      return true;
    }

    let assets = instructionHash.assets || [];
    assets.push({
      assetSignedUrl: '',
      brief: {
        assetSignedUrl: '',
      },
    });

    if (section == 'action.instructions') {
      state.signedUrls.action.instructions[stepIndex].assets = assets;
    } else if (section == 'analysis.instructions') {
      state.signedUrls.analysis.instructions[stepIndex].assets = assets;
    } else if (section == 'application.more_practice') {
      state.signedUrls.application.more_practice[stepIndex].assets = assets;
    } else if (section == 'application.homework') {
      state.signedUrls.application.homework.assets = assets;
    } else if (section == 'analysis.listen_and_read') {
      state.signedUrls.analysis.listen_and_read.assets = assets;
    } else if (section != '') {
      Vue.set(get(state.signedUrls, newPath), 'assets', assets);
    }
  },
  removeAssest(state, payload) {
    let { stepIndex, section, assetIndex } = payload;
    let instructionHash = {};
    let newPath = '';

    if (stepIndex != null && !isNaN(stepIndex)) {
      newPath = `${section}[${stepIndex}]`;
    } else {
      newPath = section;
    }
    if (section == 'action.instructions') {
      instructionHash = state.data.action.instructions[stepIndex] || {};
    } else if (section == 'application.more_practice') {
      instructionHash = state.data.application.more_practice[stepIndex] || {};
    } else if (section == 'analysis.instructions') {
      instructionHash = state.data.analysis.instructions[stepIndex] || {};
    } else if (section == 'application.homework') {
      instructionHash = state.data.application.homework || {};
    } else if (section == 'analysis.listen_and_read') {
      instructionHash = state.data.analysis.listen_and_read || {};
    } else if (section != '') {
      instructionHash = get(state.data, newPath);
    } else {
      return true;
    }

    let assets = instructionHash.assets || [];
    assets.splice(assetIndex, 1);

    if (section == 'action.instructions') {
      Vue.set(state.data.action.instructions, stepIndex, instructionHash);
    } else if (section == 'application.more_practice') {
      Vue.set(state.data.application.more_practice, stepIndex, instructionHash);
    } else if (section == 'analysis.instructions') {
      Vue.set(state.data.analysis.instructions, stepIndex, instructionHash);
    } else if (section == 'application.homework') {
      state.data.application.homework = instructionHash;
    } else if (section == 'analysis.listen_and_read') {
      state.data.analysis.listen_and_read.assets = assets;
    } else {
      if (stepIndex != undefined && !isNaN(stepIndex)) {
        Vue.set(get(state.data, section), stepIndex, instructionHash);
      } else {
        Object.assign(get(state.data, section), instructionHash);
      }
    }
  },
  removeSignedUrlForActionAssest(state, payload) {
    let { stepIndex, section } = payload;
    let instructionHash = {};
    let newPath = '';

    if (stepIndex != null && !isNaN(stepIndex)) {
      newPath = `${section}[${stepIndex}]`;
    } else {
      newPath = section;
    }
    if (section == 'action.instructions') {
      instructionHash = state.signedUrls.action.instructions[stepIndex] || {};
    } else if (section == 'application.more_practice') {
      instructionHash = state.signedUrls.application.more_practice[stepIndex] || {};
    } else if (section == 'analysis.instructions') {
      instructionHash = state.signedUrls.analysis.instructions[stepIndex] || {};
    } else if (section == 'application.homework') {
      instructionHash = state.signedUrls.application.homework || {};
    } else if (section != '') {
      instructionHash = get(state.signedUrls, newPath);
    } else {
      return true;
    }

    let assets = instructionHash.assets || [];
    assets.splice(payload.assetIndex, 1);

    if (section == 'action.instructions') {
      state.signedUrls.action.instructions[stepIndex].assets = assets;
      Vue.set(state.signedUrls.action.instructions, stepIndex, instructionHash);
    } else if (section == 'application.more_practice') {
      state.signedUrls.application.more_practice[stepIndex].assets = assets;
      Vue.set(state.signedUrls.application.more_practice, stepIndex, instructionHash);
    } else if (section == 'analysis.instructions') {
      state.signedUrls.analysis.instructions[stepIndex].assets = assets;
      Vue.set(state.signedUrls.analysis.instructions, stepIndex, instructionHash);
    } else if (section == 'application.homework') {
      state.signedUrls.application.homework = instructionHash;
    } else {
      if (stepIndex != undefined && !isNaN(stepIndex)) {
        Vue.delete(state.signedUrls, `${section}[${stepIndex}]assets[${payload.assetIndex}]`);
      } else {
        Object.assign(get(state.signedUrls, section), instructionHash);
      }
    }
  },
  addActionStep(state, { index }) {
    let defaultStepData = {
      description: '',
      children: [],
      assets: [],
    };
    let defaultStepSignedUrlData = {
      assets: [
        {
          brief: {
            assetSignedUrl: '',
          },
        },
      ],
    };
    if (index) {
      state.data.action.instructions.splice(index, 0, defaultStepData);
      state.signedUrls.action.instructions.splice(index, 0, defaultStepSignedUrlData);
      //This is needed to maintain reactivity within array
      Vue.set(state.data.action.instructions, index, defaultStepData);
      Vue.set(state.signedUrls.action.instructions, index, defaultStepSignedUrlData);
    } else {
      Vue.set(state.data.action.instructions, defaultStepData);
      Vue.set(state.signedUrls.action.instructions, defaultStepSignedUrlData);
    }
  },
  addActionSubStep(state, stepIndex) {
    if (!state.data.action.instructions[stepIndex].children) {
      Vue.set(state.data.action.instructions[stepIndex], 'children', []);
    }
    state.data.action.instructions[stepIndex].children.push({
      description: '',
    });
  },
  deleteActionStep(state, index) {
    state.data.action.instructions.splice(index, 1);
    state.signedUrls.action.instructions.splice(index, 1);
  },
  deleteActionSubStep(state, payload) {
    const stepIndex = payload.stepIndex;
    const subStepIndex = payload.subStepIndex;
    state.data.action.instructions[stepIndex].children.splice(subStepIndex, 1);
  },
  updateInstructionBreakValueOfStep(state, payload) {
    let { index, instructionBreak, section } = payload;
    let instructionsArray = lodash.get(state.data, section);
    if (instructionsArray) {
      instructionsArray[index].instructionBreak = instructionBreak || false;
    }
  },
  ////////////////////////////////
  //////// Analyse Steps ////////
  addAnalysisDiscussionStep(state, { index }) {
    let defaultStepData = {
      description: '',
      homework: {
        question: null,
        question_answer: null,
      },
    };
    let defaultStepSignedUrlData = {
      homework: {
        question: {
          assetSignedUrl: '',
          brief: {
            assetSignedUrl: '',
          },
        },
        question_answer: {
          assetSignedUrl: '',
          brief: {
            assetSignedUrl: '',
          },
        },
      },
    };

    if (index) {
      state.data.analysis.discussion.instructions.splice(index, 0, defaultStepData);
      state.signedUrls.analysis.discussion.instructions.splice(index, 0, defaultStepSignedUrlData);
    } else {
      state.data.analysis.discussion.instructions.push(defaultStepData);
    }
  },
  addAnalysisInstructionStep(state, { index }) {
    let defaultStepData = {
      description: '',
      assets: [],
    };
    if (!state.data.analysis.instructions) {
      Vue.set(state.data.analysis, 'instructions', []);
    }

    if (index) {
      state.data.analysis.instructions.splice(index, 0, defaultStepData);
    } else {
      state.data.analysis.instructions.push(defaultStepData);
    }
  },
  deleteAnalysisDiscussionStep(state, index) {
    state.data.analysis.discussion.instructions.splice(index, 1);
    state.signedUrls.analysis.discussion.instructions.splice(index, 1);
  },
  addInstructionStepForPath(state, { index, path, hasSubStep, hasAsset }) {
    let defaultStepData = {
      description: '',
    };
    if (hasSubStep) {
      defaultStepData.children = [];
    }
    if (hasAsset) {
      defaultStepData.assets = [];
    }
    let defaultStepSignedUrlData = {
      assets: [
        {
          brief: {
            assetSignedUrl: '',
          },
        },
      ],
    };

    const instructionsPath = parsePath(path);
    let dataInstructions = getNestedObjectWithPath(state.data, instructionsPath);
    let signedUrlsInstructions = getNestedObjectWithPath(state.signedUrls, instructionsPath);

    if (index !== undefined) {
      dataInstructions.splice(index, 0, defaultStepData);
      Vue.set(dataInstructions, dataInstructions.length - 1, defaultStepData);
      if (hasAsset) {
        signedUrlsInstructions.splice(index, 0, defaultStepSignedUrlData);
        Vue.set(signedUrlsInstructions, index, defaultStepSignedUrlData);
      }
    } else {
      dataInstructions.push(defaultStepData);
      Vue.set(dataInstructions, dataInstructions.length - 1, defaultStepData);
      if (hasAsset) {
        Vue.set(
          signedUrlsInstructions,
          signedUrlsInstructions.length - 1,
          defaultStepSignedUrlData
        );
        signedUrlsInstructions.push(defaultStepSignedUrlData);
      }
    }
  },
  addInstructionSubStepForPath(state, { stepIndex, path }) {
    const instructionsPath = parsePath(path);
    let dataInstructions = getNestedObjectWithPath(state.data, instructionsPath);

    if (!dataInstructions[stepIndex].children) {
      Vue.set(dataInstructions[stepIndex], 'children', []);
    }
    dataInstructions[stepIndex].children.push({
      description: '',
    });
  },
  deleteInstructionStepForPath(state, { index, path, hasAsset }) {
    const instructionsPath = parsePath(path);
    let dataInstructions = getNestedObject(state.data, instructionsPath);
    let signedUrlsInstructions = getNestedObject(state.signedUrls, instructionsPath);

    dataInstructions.splice(index, 1);
    if (hasAsset) {
      signedUrlsInstructions.splice(index, 1);
    }
  },
  deleteInstructionSubStepForPath(state, { stepIndex, subStepIndex, path }) {
    const instructionsPath = parsePath(path);
    let dataInstructions = getNestedObject(state.data, instructionsPath);

    dataInstructions[stepIndex].children.splice(subStepIndex, 1);
  },
  addPhonicsAnalysisStep(state, { index, sectionKey }) {
    let defaultStepData = {
      description: '',
    };

    if (index) {
      state.data.analysis[sectionKey].instructions.splice(index, 0, defaultStepData);
    } else {
      state.data.analysis[sectionKey].instructions.push(defaultStepData);
    }
  },
  deletePhonicsAnalysisStep(state, { index, sectionKey }) {
    state.data.analysis[sectionKey].instructions.splice(index, 1);
  },
  addPhonicsAnalysisSubStep(state, { index, sectionKey }) {
    if (state.data.analysis[sectionKey].instructions[index].children == undefined) {
      Vue.set(state.data.analysis[sectionKey].instructions[index], 'children', []);
    }
    state.data.analysis[sectionKey].instructions[index].children.push({
      description: '',
    });
    console.log(state.data.analysis);
  },
  deletePhonicsAnalysisSubStep(state, { stepIndex, subStepIndex, sectionKey }) {
    state.data.analysis[sectionKey].instructions[stepIndex].children.splice(subStepIndex, 1);
  },
  deleteAnalysisDiscussionSubStep(state, payload) {
    const { stepIndex, subStepIndex } = payload;
    state.data.analysis.discussion.instructions[stepIndex].children.splice(subStepIndex, 1);
  },
  addAnalysisActivityDiscussionStep(state, { index }) {
    let defaultStepData = {
      description: '',
    };

    if (index) {
      state.data.analysis.activity_discussion.instructions.splice(index, 0, defaultStepData);
    } else {
      state.data.analysis.activity_discussion.instructions.push(defaultStepData);
    }
  },
  deleteAnalysisActivityDiscussionStep(state, index) {
    state.data.analysis.activity_discussion.instructions.splice(index, 1);
  },
  deleteDiscussionStepInstruction(state, index) {
    state.data.analysis.instructions.splice(index, 1);
  },
  addPractice(state, { index }) {
    let defaultStepData = {
      description: '',
      assets: [],
    };
    let defaultStepSignedUrlData = {
      assets: [],
    };

    if (index) {
      state.data.application.more_practice.splice(index, 0, defaultStepData);
      state.signedUrls.application.more_practice.splice(index, 0, {
        assets: [],
      });
    } else {
      state.data.action.instructions.push(defaultStepData);
      state.signedUrls.action.instructions.push(defaultStepSignedUrlData);
    }
  },
  addPracticeForStep(state, { index, path }) {
    let defaultStepData = {
      description: '',
      assets: [],
    };
    let defaultStepSignedUrlData = {
      assets: [],
    };

    const instructionsPath = parsePath(path);
    let dataInstructions = getNestedObjectWithPath(state.data, instructionsPath);
    let signedUrlsInstructions = getNestedObjectWithPath(state.signedUrls, instructionsPath);

    if (index) {
      dataInstructions.splice(index, 0, defaultStepData);
      signedUrlsInstructions.splice(index, 0, defaultStepSignedUrlData);
      Vue.set(dataInstructions, index, defaultStepData);
      Vue.set(signedUrlsInstructions, index, defaultStepSignedUrlData);
    } else {
      dataInstructions.push(defaultStepData);
      signedUrlsInstructions.push(defaultStepSignedUrlData);
      Vue.set(dataInstructions, dataInstructions.length - 1, defaultStepData);
      Vue.set(signedUrlsInstructions, signedUrlsInstructions.length - 1, defaultStepSignedUrlData);
    }
  },
  updateApplicationClassworkDescription(state, description) {
    state.data.assessment.check.description = description;
    state.data.application.classwork.description = description;
  },
  deletePractice(state, index) {
    state.data.application.more_practice.splice(index, 1);
  },
  deletePracticeForStep(state, { index, path }) {
    const instructionsPath = parsePath(path);
    let dataInstructions = getNestedObject(state.data, instructionsPath);
    let signedUrlsInstructions = getNestedObject(state.signedUrls, instructionsPath);

    dataInstructions.splice(index, 1);
    signedUrlsInstructions.splice(index, 1);
  },
  addAnalysisHigherOrderStep(state, { index }) {
    let defaultStepDate = {
      description: '',
    };

    if (index) {
      state.data.analysis.higher_order_thinking.instructions.splice(index, 0, defaultStepDate);
    } else {
      state.data.analysis.higher_order_thinking.instructions.push(defaultStepDate);
    }
  },
  deleteAnalysisHigherOrderStep(state, index) {
    state.data.analysis.higher_order_thinking.instructions.splice(index, 1);
  },
  addAnalysisGeneralizedDiscussionStep(state, { index }) {
    let defaultStepData = {
      description: '',
    };
    if (index) {
      state.data.analysis.generalized_discussion.instructions.splice(index, 0, defaultStepData);
    } else {
      state.data.analysis.generalized_discussion.instructions.push(defaultStepData);
    }
  },
  deleteAnalysisGeneralizedDiscussionStep(state, index) {
    state.data.analysis.generalized_discussion.instructions.splice(index, 1);
  },
  addAnaysisExplanationAssets(state, type) {
    const DEFAULT_ASSET_HASH = {
      description: '',
      assetId: '',
      brief: {
        text: '',
        assetId: '',
      },
      assetApproved: false,
    };
    const DEFAULT_SIGNED_URLS_HASH = {
      brief: {
        assetSignedUrl: '',
      },
      assetSignedUrl: '',
      loadingStatus: 'not_started',
    };
    switch (type) {
      case AssetTypes.ACTIVITY_EXPLANATION_ASSET_TYPE:
        setNextArrayIndexWith(
          state.data.analysis.activity_discussion.explanations,
          DEFAULT_ASSET_HASH
        );
        if(!state.signedUrls.analysis.activityDiscussion.explanations) {
          setNestedObject(state.signedUrls, 'analysis.activityDiscussion.explanations', []);
        }
        setNextArrayIndexWith(
          state.signedUrls.analysis.activityDiscussion.explanations,
          DEFAULT_SIGNED_URLS_HASH
        );
        break;
      case AssetTypes.GENERALIZED_EXPLANATION_ASSET_TYPE:
        setNextArrayIndexWith(
          state.data.analysis.generalized_discussion.explanations,
          DEFAULT_ASSET_HASH
        );
        if(!state.signedUrls.analysis.generalizedDiscussion.explanations) {
          setNestedObject(state.signedUrls, 'analysis.generalizedDiscussion.explanations', []);
        }
        setNextArrayIndexWith(
          state.signedUrls.analysis.generalizedDiscussion.explanations,
          DEFAULT_SIGNED_URLS_HASH
        );
        break;
      case AssetTypes.HIGHER_ORDER_THINKING_KEY_QUESTION_ASSET_TYPE:
        if (!state.data.analysis.higher_order_thinking.key_question_assets) {
          Vue.set(state.data.analysis.higher_order_thinking, 'key_question_assets', []);
          Vue.set(state.signedUrls.analysis.higherOrderThinking, 'key_question_assets', []);
        }
        setNextArrayIndexWith(
          state.data.analysis.higher_order_thinking.key_question_assets,
          DEFAULT_ASSET_HASH
        );
        setNextArrayIndexWith(
          state.signedUrls.analysis.higherOrderThinking.key_question_assets,
          DEFAULT_SIGNED_URLS_HASH
        );
        break;
      case AssetTypes.ACTIVITY_DISCUSSION_KEY_QUESTION_ASSET_TYPE:
        if (!state.data.analysis.activity_discussion.key_question_assets) {
          Vue.set(state.data.analysis.activity_discussion, 'key_question_assets', []);
          Vue.set(state.signedUrls.analysis.activityDiscussion, 'key_question_assets', []);
        }
        if(!state.signedUrls.analysis.activityDiscussion.key_question_assets) {
          Vue.set(state.signedUrls.analysis.activityDiscussion, 'key_question_assets', []);
        }
        setNextArrayIndexWith(
          state.data.analysis.activity_discussion.key_question_assets,
          DEFAULT_ASSET_HASH
        );
        setNextArrayIndexWith(
          state.signedUrls.analysis.activityDiscussion.key_question_assets,
          DEFAULT_SIGNED_URLS_HASH
        );
        break;
      case AssetTypes.GENERALIZED_DISCUSSION_KEY_QUESTION_ASSET_TYPE:
        if (!state.data.analysis.generalized_discussion.key_question_assets) {
          Vue.set(state.data.analysis.generalized_discussion, 'key_question_assets', []);
          Vue.set(state.signedUrls.analysis.generalizedDiscussion, 'key_question_assets', []);
        }
        if(!state.signedUrls.analysis.generalizedDiscussion.key_question_assets) {
          Vue.set(state.signedUrls.analysis.generalizedDiscussion, 'key_question_assets', []);
        }
        setNextArrayIndexWith(
          state.data.analysis.generalized_discussion.key_question_assets,
          DEFAULT_ASSET_HASH
        );
        setNextArrayIndexWith(
          state.signedUrls.analysis.generalizedDiscussion.key_question_assets,
          DEFAULT_SIGNED_URLS_HASH
        );
        break;
      case AssetTypes.HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE:
        setNextArrayIndexWith(
          state.data.analysis.higher_order_thinking.explanations,
          DEFAULT_ASSET_HASH
        );
        if(!state.signedUrls.analysis.higherOrderThinking.explanations) {
          setNestedObject(state.signedUrls, 'analysis.higherOrderThinking.explanations', []);
        }
        setNextArrayIndexWith(
          state.signedUrls.analysis.higherOrderThinking.explanations,
          DEFAULT_SIGNED_URLS_HASH
        );
        break;
      case AssetTypes.EP_GENERALIZED_EXPLANATION_ASSET_TYPE:
        setNextArrayIndexWith(
          state.data.analysis.discussion.explanations.explanations,
          DEFAULT_ASSET_HASH
        );
        setNextArrayIndexWith(
          state.signedUrls.analysis.discussion.explanations,
          DEFAULT_SIGNED_URLS_HASH
        );
        break;
      default:
        Vue.notify({
          title: 'Error!',
          text: 'Error: Unknown Analyse Asset Type!',
          type: 'error',
        });
    }
  },
  addExplanationAssetForPath(state, { path }) {
    const DEFAULT_ASSET_HASH = {
      description: '',
      assetId: '',
      brief: {
        text: '',
        assetId: '',
      },
      assetApproved: false,
    };

    const DEFAULT_SIGNED_URLS_HASH = {
      brief: {
        assetSignedUrl: '',
      },
      assetSignedUrl: '',
      loadingStatus: 'not_started',
    };

    const setPathValue = (path, value) => {
      const keys = path.split('.');
      let obj = state;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!obj[keys[i]]) {
          Vue.set(obj, keys[i], {});
        }
        obj = obj[keys[i]];
      }
      if (!obj[keys[keys.length - 1]]) {
        Vue.set(obj, keys[keys.length - 1], []);
      }
      obj[keys[keys.length - 1]].push(value);
    };

    setPathValue(`data.${path}`, DEFAULT_ASSET_HASH);
    setPathValue(`signedUrls.${path}`, DEFAULT_SIGNED_URLS_HASH);
  },
  deleteAnaysisExplanationAssets(state, payload) {
    let { index, assetType } = payload;
    switch (assetType) {
      case AssetTypes.ACTIVITY_EXPLANATION_ASSET_TYPE:
        state.data.analysis.activity_discussion.explanations.splice(index, 1);
        state.signedUrls.analysis.activityDiscussion.explanations.splice(index, 1);
        break;
      case AssetTypes.GENERALIZED_EXPLANATION_ASSET_TYPE:
        state.data.analysis.generalized_discussion.explanations.splice(index, 1);
        state.signedUrls.analysis.generalizedDiscussion.explanations.splice(index, 1);
        break;
      case AssetTypes.HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE:
        state.data.analysis.higher_order_thinking.explanations.splice(index, 1);
        state.signedUrls.analysis.higherOrderThinking.explanations.splice(index, 1);
        break;
      case AssetTypes.HIGHER_ORDER_THINKING_KEY_QUESTION_ASSET_TYPE:
        state.data.analysis.higher_order_thinking.key_question_assets.splice(index, 1);
        state.signedUrls.analysis.higherOrderThinking.key_question_assets.splice(index, 1);
        break;
      case AssetTypes.ACTIVITY_DISCUSSION_KEY_QUESTION_ASSET_TYPE:
        state.data.analysis.activity_discussion.key_question_assets.splice(index, 1);
        state.signedUrls.analysis.activityDiscussion.key_question_assets.splice(index, 1);
        break;
      case AssetTypes.GENERALIZED_DISCUSSION_KEY_QUESTION_ASSET_TYPE:
        state.data.analysis.generalized_discussion.key_question_assets.splice(index, 1);
        state.signedUrls.analysis.generalizedDiscussion.key_question_assets.splice(index, 1);
        break;
      default:
        Vue.notify({
          title: 'Error!',
          text: 'Error: Unknown Analyse Asset Type!',
          type: 'error',
        });
    }
  },
  deleteAnaysisExplanationAssetsForPath(state, payload) {
    let { index, path } = payload;

    const deleteAssetAtPath = (path) => {
      const array = lodash.get(state, path);
      if (array && Array.isArray(array)) {
        array.splice(index, 1);
      }
    };

    deleteAssetAtPath(`data.${path}`);
    deleteAssetAtPath(`signedUrls.${path}`);
  },
  addReference(state) {
    state.data.references.push('');
  },
  deleteReference(state, index) {
    state.data.references.splice(index, 1);
  },
  setLessonPlanStaticTextsData(state, staticTexts) {
    state.staticTexts = staticTexts || {};
  },
  updateTimeOfBlock(state, { blockName, value }) {
    if (blockName == 'overall') {
      state.data.time = value;
    } else {
      state.data[blockName].time = value;
    }
  },
  updateTypeOfBlock(state, { blockName, value }) {
    state.data[blockName].types = value;
  },
  saveLessonPlanData(state, lessonPlanData) {
    state.data = lessonPlanData.data;
  },
  setParsedJsonToState(state, lessonPlan) {
    state.data = lessonPlan.data;
    state.diff = lessonPlan.diff;
    state.signedUrls = lessonPlan.currentStageAssetsSignedUrl;
    state.name = lessonPlan.name;
    state.lpType = lessonPlan.lpType;
    state.libraryId = lessonPlan.libraryId;
    state.currentStage = lessonPlan.currentStage;
    state.lpCurrentStageDataDailyPlanFromVuex = lessonPlan.lpCurrentStageDataDailyPlan;
    state.equationEditorJsonArray = lessonPlan.equationEditorJsonArray;
    state.subjectName = lessonPlan.subjectName;
  },
  setLibraryId(state, libraryId) {
    state.libraryId = parseInt(libraryId);
  },
  // eslint-disable-next-line
  importAssets(state, response) {
    state.data.image_url = response.coverImageUrl;
    // state.data.application.classwork.image_url = response.contentBookImageUrl;
    state.data.application.classwork.image_url = response.studentWorkbookImageUrl;
    state.data.assessment.check.question_image_url = response.studentWorkbookImageUrl;
    state.data.assessment.check.answer_image_url = response.studentWorkbookAnswerImageUrl;
    state.data.application.homework.question_image_url = response.homeworkQuestionImageUrl;
    state.data.application.homework.answer_image_url = response.homeworkAnswerImageUrl;
    // state.data.action.multimedia.url = response.actionVideoUrl;

    if (response.resources) {
      let aimResIndex = 0;

      for (let index = 0; index < response.resources.length; aimResIndex++) {
        if (
          state.data.aim.resources.length <= index ||
          state.data.aim.resources.length <= aimResIndex
        ) {
          state.data.aim.resources.push({
            text: '',
            image_url: response.resources[index],
            brief: '',
          });
          index++;
        } else {
          let text = state.data.aim.resources[aimResIndex].text;
          if (text.toUpperCase().indexOf('CONTENT BOOK') !== -1) {
            // set content book image_url
            state.data.aim.resources[aimResIndex].image_url = response.contentBookImageUrl;
          } else if (text.toUpperCase().indexOf('WORKSHEET') !== -1) {
            // set student work book image_url
            state.data.aim.resources[aimResIndex].image_url = response.studentWorkbookImageUrl;
          } else {
            state.data.aim.resources[aimResIndex].image_url = response.resources[index];
            index++;
          }
        }
      }
    }
  },
  importSingleAsset(state, response) {
    let isAssetUploadRequired = (this.isAssetUploadRequired = getAssetUploadRequiredStatus(
      state.currentStage
    ));
    var forceUpdateImageUrl = response.forceApproveAsset != null;
    const isAssetApproved =
      (response.forceApproveAsset == true ||
        state.currentStage == 'assetAuthorizingStage' ||
        state.currentStage == 'proofReadingStage') &&
      response.id != '';
    let assetKey = 'assetId';
    let signedUrlKey = 'assetSignedUrl';
    let typeKey = 'type';

    if (!(isAssetUploadRequired || forceUpdateImageUrl)) {
      assetKey = 'brief.assetId';
      signedUrlKey = 'brief.assetSignedUrl';
      typeKey = 'brief.type';
    }

    switch (response.assetType) {
      case AssetTypes.COVER_IMAGE_ASSET_TYPE:
        lodash.set(state.data, assetKey, response.id);
        lodash.set(state.signedUrls, signedUrlKey, response.signedUrl);
        Vue.set(state.data, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.CONTENT_BOOK_ASSET_TYPE:
        // state.data.application.classwork.image_url = response.path;
        break;
      case AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE:
        lodash.set(state.data.assessment.check.question, assetKey, response.id);
        lodash.set(state.data.application.classwork, assetKey, response.id);

        lodash.set(
          state.data.assessment.check.question,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        lodash.set(
          state.data.application.classwork,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );

        Vue.set(state.data.assessment.check.question, 'assetApproved', isAssetApproved);
        Vue.set(state.data.application.classwork, 'assetApproved', isAssetApproved);

        lodash.set(state.signedUrls.assessment.check.question, signedUrlKey, response.signedUrl);
        lodash.set(state.signedUrls.application.classwork, signedUrlKey, response.signedUrl);
        break;
      case AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE:
        lodash.set(state.data.assessment.check.question_answer, assetKey, response.id);
        lodash.set(
          state.signedUrls.assessment.check.question_answer,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.assessment.check.question_answer,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.assessment.check.question_answer, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE:
        lodash.set(state.data.application.homework.question, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.homework.question,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.homework.question,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.application.homework.question, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE:
        lodash.set(state.data.application.homework.question_answer, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.homework.question_answer,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.homework.question_answer,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.application.homework.question_answer, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.ACTION_VIDEO_ASSET_TYPE:
        // state.data.action.multimedia.url = response.id;
        break;
      case AssetTypes.EP_HOMEWORK_QUESTION_ASSET_TYPE:
        lodash.set(state.data.application.homework.question, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.homework.question,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.homework.question,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.application.homework.question, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.EP_HOMEWORK_QUESTION_ANSWER_ASSET_TYPE:
        lodash.set(state.data.application.homework.question_answer, assetKey, response.id);
        state.data.application.homework.question_answer.type = getFileTypeFromUrl(
          response.signedUrl
        );
        lodash.set(
          state.signedUrls.application.homework.question_answer,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.homework.question_answer,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.application.homework.question_answer, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.EP_CLASSWORK_QUESTION_ASSET_TYPE:
        lodash.set(state.data.application.classwork.question, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.classwork.question,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.classwork.question,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.application.classwork.question, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.PRACTICE_HOMEWORK_PROJECT_WORK_ASSET:
        lodash.set(state.data.application.homework.project_work, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.homework.project_work,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.homework.project_work,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.application.homework.project_work, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.PRACTICE_HOMEWORK_MY_LEARNING_CHART_ASSET:
        lodash.set(state.data.application.homework.my_learning_chart, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.homework.my_learning_chart,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.homework.my_learning_chart,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.application.homework.my_learning_chart,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.PRACTICE_HOMEWORK_MY_LEARNING_CHART_ANSWER_ASSET:
        lodash.set(state.data.application.homework.my_learning_chart_answer, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.homework.my_learning_chart_answer,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.homework.my_learning_chart_answer,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.application.homework.my_learning_chart_answer,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.EP_CLASSWORK_QUESTION_ANSWER_ASSET_TYPE:
        lodash.set(state.data.application.classwork.question_answer, assetKey, response.id);
        lodash.set(
          state.signedUrls.application.classwork.question_answer,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.application.classwork.question_answer,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.application.classwork.question_answer, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.INFORMATION_AND_ASSESSMENT_QUESTION_ASSET_TYPE:
        lodash.set(state.data.informationAndAssessment.question, assetKey, response.id);
        lodash.set(
          state.signedUrls.informationAndAssessment.question,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.informationAndAssessment.question,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(state.data.informationAndAssessment.question, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.INFORMATION_AND_ASSESSMENT_ANSWER_ASSET_TYPE:
        lodash.set(state.data.informationAndAssessment.question_answer, assetKey, response.id);
        lodash.set(
          state.signedUrls.informationAndAssessment.question_answer,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.informationAndAssessment.question_answer,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.informationAndAssessment.question_answer,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.INFORMATION_AND_ASSESSMENT_SECONDARY_ASSET_TYPE:
        lodash.set(state.data.informationAndAssessment.secondaryAsset, assetKey, response.id);
        lodash.set(
          state.data.informationAndAssessment.secondaryAsset,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.informationAndAssessment.secondaryAsset,
          'assetApproved',
          isAssetApproved
        );
        if (!state.signedUrls.informationAndAssessment.secondaryAsset) {
          state.signedUrls.informationAndAssessment.secondaryAsset = {};
        }
        lodash.set(
          state.signedUrls.informationAndAssessment.secondaryAsset,
          signedUrlKey,
          response.signedUrl
        );
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_CONTENT_BOOK_PDF:
        lodash.set(state.data.analysis.xseed_content_book.content_book, assetKey, response.id);
        lodash.set(
          state.signedUrls.analysis.xseed_content_book.content_book,
          signedUrlKey,
          response.signedUrl
        );
        Vue.set(
          state.data.analysis.xseed_content_book.content_book,
          'assetApproved',
          isAssetApproved
        );
        lodash.set(
          state.data.analysis.xseed_content_book.content_book,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_BOARDWORK_IMAGE_PRIMARY:
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.boardwork_image_primary,
          assetKey,
          response.id
        );
        lodash.set(
          state.signedUrls.analysis.questions_to_guide_discussion.boardwork_image_primary,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.boardwork_image_primary,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.analysis.questions_to_guide_discussion.boardwork_image_primary,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_BOARDWORK_IMAGE_SECONDARY:
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.boardwork_image_secondary,
          assetKey,
          response.id
        );
        lodash.set(
          state.signedUrls.analysis.questions_to_guide_discussion.boardwork_image_secondary,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.boardwork_image_secondary,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.analysis.questions_to_guide_discussion.boardwork_image_secondary,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_QUESTION_PDF:
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          assetKey,
          response.id
        );
        lodash.set(
          state.signedUrls.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_QUESTION_IMAGE_ASSET:
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          assetKey,
          response.id
        );
        lodash.set(
          state.signedUrls.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .question_image,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_QUESTION_EXPLANATION_IMAGE_ASSET:
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .explanation_image,
          assetKey,
          response.id
        );
        lodash.set(
          state.signedUrls.analysis.questions_to_guide_discussion.questions[response.index]
            .explanation_image,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .explanation_image,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.analysis.questions_to_guide_discussion.questions[response.index]
            .explanation_image,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.DISCUSSION_APPLICATION_IMAGE:
        lodash.set(state.data.application.image, assetKey, response.id);
        lodash.set(state.signedUrls.application.image, signedUrlKey, response.signedUrl);
        lodash.set(state.data.application.image, typeKey, getFileTypeFromUrl(response.signedUrl));
        Vue.set(state.data.application.image, 'assetApproved', isAssetApproved);
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_IMAGE_ASSET:
        lodash.set(
          state.data.analysis.discussion.instructions[response.index].homework.question,
          assetKey,
          response.id
        );
        lodash.set(
          state.signedUrls.analysis.discussion.instructions[response.index].homework.question,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.analysis.discussion.instructions[response.index].homework.question,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.analysis.discussion.instructions[response.index].homework.question,
          'assetApproved',
          isAssetApproved
        );
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_ANSWER_IMAGE_ASSET:
        lodash.set(
          state.data.analysis.discussion.instructions[response.index].homework.question_answer,
          assetKey,
          response.id
        );
        lodash.set(
          state.signedUrls.analysis.discussion.instructions[response.index].homework
            .question_answer,
          signedUrlKey,
          response.signedUrl
        );
        lodash.set(
          state.data.analysis.discussion.instructions[response.index].homework.question_answer,
          typeKey,
          getFileTypeFromUrl(response.signedUrl)
        );
        Vue.set(
          state.data.analysis.discussion.instructions[response.index].homework.question_answer,
          'assetApproved',
          isAssetApproved
        );
        break;
    }
  },
  importSingleResourceAsset(state, { response, resourceIndex, forceApproveAsset, identifier }) {
    switch (response.assetType) {
      case AssetTypes.RESOURCES_ASSET_TYPE:
        if (state.data.aim.resources && state.data.aim.resources[resourceIndex]) {
          let forceUpdateImageUrl = forceApproveAsset != null;
          let isAssetApproved =
            (forceApproveAsset == true ||
              state.currentStage == 'assetAuthorizingStage' ||
              state.currentStage == 'proofReadingStage') &&
            response.id != '';

          let isAssetUploadRequired = (this.isAssetUploadRequired = getAssetUploadRequiredStatus(
            state.currentStage
          ));
          let assetKey = forceUpdateImageUrl || isAssetUploadRequired ? 'assetId' : 'brief.assetId';
          let signedUrlKey =
            forceUpdateImageUrl || isAssetUploadRequired
              ? 'assetSignedUrl'
              : 'brief.assetSignedUrl';
          // https://vuejs.org/v2/guide/list.html#Mutation-Methods
          // https://vuejs.org/v2/guide/list.html#Caveats

          let resource = state.data.aim.resources[resourceIndex];
          lodash.set(resource, assetKey, response.id);
          lodash.set(
            state.signedUrls.aim.resources[resourceIndex],
            signedUrlKey,
            response.signedUrl
          );
          state.signedUrls.aim.resources[resourceIndex].loadingStatus = response.loadingStatus;
          Vue.set(resource, 'assetApproved', isAssetApproved);

          lodash.set(state.data.aim.resources, resourceIndex, resource);
        } else if (identifier && get(state.data, identifier)) {
          let forceUpdateImageUrl = forceApproveAsset != null;
          let isAssetApproved =
            (forceApproveAsset == true ||
              state.currentStage == 'assetAuthorizingStage' ||
              state.currentStage == 'proofReadingStage') &&
            response.id != '';

          const isAssetUploadRequired = getAssetUploadRequiredStatus(state.currentStage);

          let assetKey = forceUpdateImageUrl || isAssetUploadRequired ? 'assetId' : 'brief.assetId';
          console.log(assetKey);
          let signedUrlKey =
            forceUpdateImageUrl || isAssetUploadRequired
              ? 'assetSignedUrl'
              : 'brief.assetSignedUrl';
          // https://vuejs.org/v2/guide/list.html#Mutation-Methods
          // https://vuejs.org/v2/guide/list.html#Caveats

          let resource = get(state.data, identifier);
          try {
            setNestedObject(resource, assetKey, response.id);
          } catch (e) {
            console.log(identifier);
          }
          try {
            setNestedObject(state.signedUrls, `${identifier}.${signedUrlKey}`, response.signedUrl);
          } catch (e) {
            console.log(identifier);
            console.log(e);
          }
          setNestedObject(state.signedUrls, `${identifier}.loadingStatus`, response.loadingStatus);
          console.log(get(state.signedUrls, `${identifier}.loadingStatus`));
          Vue.set(resource, 'assetApproved', isAssetApproved);

          setNestedObject(state.signedUrls, `${identifier}.loadingStatus`, response.loadingStatus);
          setNestedObject(state.data, identifier, resource);
        }
    }
  },
  importSingleAssetActionByIdentifier(
    state,
    { response, forceApproveAsset, identifier, assetUrlIdentifier }
  ) {
    if (identifier && get(state.data, identifier)) {
      let forceUpdateImageUrl = forceApproveAsset != null;
      let isAssetApproved =
        (forceApproveAsset == true ||
          state.currentStage == 'assetAuthorizingStage' ||
          state.currentStage == 'proofReadingStage') &&
        response.id != '';

      if (!assetUrlIdentifier) {
        assetUrlIdentifier = identifier;
      }

      const isAssetUploadRequired = getAssetUploadRequiredStatus(state.currentStage);

      let assetKey = forceUpdateImageUrl || isAssetUploadRequired ? 'assetId' : 'brief.assetId';
      let signedUrlKey =
        forceUpdateImageUrl || isAssetUploadRequired ? 'assetSignedUrl' : 'brief.assetSignedUrl';
      // https://vuejs.org/v2/guide/list.html#Mutation-Methods
      // https://vuejs.org/v2/guide/list.html#Caveats

      let resource = get(state.data, identifier);
      setNestedObject(resource, assetKey, response.id);
      try {
        setNestedObject(
          state.signedUrls,
          `${assetUrlIdentifier}.${signedUrlKey}`,
          response.signedUrl
        );
      } catch (e) {
        console.log(identifier);
        console.log(e);
      }
      setNestedObject(
        state.signedUrls,
        `${assetUrlIdentifier}.loadingStatus`,
        response.loadingStatus
      );
      console.log(get(state.signedUrls, `${assetUrlIdentifier}.loadingStatus`));
      Vue.set(resource, 'assetApproved', isAssetApproved);

      setNestedObject(
        state.signedUrls,
        `${assetUrlIdentifier}.loadingStatus`,
        response.loadingStatus
      );
      setNestedObject(state.data, identifier, resource);
    }
  },
  importAnalysisExplanationAsset(state, { response, resourceIndex, forceApproveAsset }) {
    let forceUpdateImageUrl = forceApproveAsset != null;
    let isAssetApproved =
      (forceApproveAsset == true ||
        state.currentStage == 'assetAuthorizingStage' ||
        state.currentStage == 'proofReadingStage') &&
      response.id != '';
    let isAssetUploadRequired = (this.isAssetUploadRequired = getAssetUploadRequiredStatus(
      state.currentStage
    ));
    let assetKey = forceUpdateImageUrl || isAssetUploadRequired ? 'assetId' : 'brief.assetId';
    let signedUrlKey =
      forceUpdateImageUrl || isAssetUploadRequired ? 'assetSignedUrl' : 'brief.assetSignedUrl';
    let assetApprovedKey = 'assetApproved';
    switch (response.assetType) {
      case AssetTypes.ACTIVITY_EXPLANATION_ASSET_TYPE:
        if (state.data.analysis.activity_discussion.explanations[resourceIndex]) {
          let asset = state.data.analysis.activity_discussion.explanations[resourceIndex];
          let signedUrl = state.signedUrls.analysis.activityDiscussion.explanations[resourceIndex];
          lodash.set(asset, assetKey, response.id);
          asset[assetApprovedKey] = isAssetApproved;
          lodash.set(signedUrl, signedUrlKey, response.signedUrl);
          Vue.set(signedUrl, 'loadingStatus', response.loadingStatus);
        }
        break;
      case AssetTypes.GENERALIZED_EXPLANATION_ASSET_TYPE:
        if (state.data.analysis.generalized_discussion.explanations[resourceIndex]) {
          let asset = state.data.analysis.generalized_discussion.explanations[resourceIndex];
          let signedUrl =
            state.signedUrls.analysis.generalizedDiscussion.explanations[resourceIndex];
          lodash.set(asset, assetKey, response.id);
          asset[assetApprovedKey] = isAssetApproved;
          lodash.set(signedUrl, signedUrlKey, response.signedUrl);
          Vue.set(signedUrl, 'loadingStatus', response.loadingStatus);
        }
        break;
      case AssetTypes.HIGHER_ORDER_THINKING_KEY_QUESTION_ASSET_TYPE:
        if (state.data.analysis.higher_order_thinking.key_question_assets[resourceIndex]) {
          let asset = state.data.analysis.higher_order_thinking.key_question_assets[resourceIndex];
          let signedUrl =
            state.signedUrls.analysis.higherOrderThinking.key_question_assets[resourceIndex];
          lodash.set(asset, assetKey, response.id);
          asset[assetApprovedKey] = isAssetApproved;
          lodash.set(signedUrl, signedUrlKey, response.signedUrl);
          Vue.set(signedUrl, 'loadingStatus', response.loadingStatus);
        }
        break;
      case AssetTypes.ACTIVITY_DISCUSSION_KEY_QUESTION_ASSET_TYPE:
        if (state.data.analysis.activity_discussion.key_question_assets[resourceIndex]) {
          let asset = state.data.analysis.activity_discussion.key_question_assets[resourceIndex];
          let signedUrl =
            state.signedUrls.analysis.activityDiscussion.key_question_assets[resourceIndex];
          lodash.set(asset, assetKey, response.id);
          asset[assetApprovedKey] = isAssetApproved;
          lodash.set(signedUrl, signedUrlKey, response.signedUrl);
          Vue.set(signedUrl, 'loadingStatus', response.loadingStatus);
        }
        break;
      case AssetTypes.GENERALIZED_DISCUSSION_KEY_QUESTION_ASSET_TYPE:
        if (state.data.analysis.generalized_discussion.key_question_assets[resourceIndex]) {
          let asset = state.data.analysis.generalized_discussion.key_question_assets[resourceIndex];
          let signedUrl =
            state.signedUrls.analysis.generalizedDiscussion.key_question_assets[resourceIndex];
          lodash.set(asset, assetKey, response.id);
          asset[assetApprovedKey] = isAssetApproved;
          lodash.set(signedUrl, signedUrlKey, response.signedUrl);
          Vue.set(signedUrl, 'loadingStatus', response.loadingStatus);
        }
        break;
      case AssetTypes.HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE:
        if (state.data.analysis.higher_order_thinking.explanations[resourceIndex]) {
          let asset = state.data.analysis.higher_order_thinking.explanations[resourceIndex];
          let signedUrl = state.signedUrls.analysis.higherOrderThinking.explanations[resourceIndex];
          lodash.set(asset, assetKey, response.id);
          asset[assetApprovedKey] = isAssetApproved;
          lodash.set(signedUrl, signedUrlKey, response.signedUrl);
          Vue.set(signedUrl, 'loadingStatus', response.loadingStatus);
        }
        break;
    }
  },
  importExplanationAssetActionForPath(state, { response, resourceIndex, path, forceApproveAsset }) {
    const forceUpdateImageUrl = forceApproveAsset != null;
    const isAssetApproved =
      (forceApproveAsset === true ||
        state.currentStage === 'assetAuthorizingStage' ||
        state.currentStage === 'proofReadingStage') &&
      response.id !== '';
    const isAssetUploadRequired = getAssetUploadRequiredStatus(state.currentStage);
    const assetKey = forceUpdateImageUrl || isAssetUploadRequired ? 'assetId' : 'brief.assetId';
    const signedUrlKey =
      forceUpdateImageUrl || isAssetUploadRequired ? 'assetSignedUrl' : 'brief.assetSignedUrl';
    const assetApprovedKey = 'assetApproved';

    const setAssetAndSignedUrl = (assetPath, signedUrlPath) => {
      const asset = lodash.get(state, `${assetPath}[${resourceIndex}]`);
      const signedUrl = lodash.get(state, `${signedUrlPath}[${resourceIndex}]`);
      if (asset && signedUrl) {
        lodash.set(asset, assetKey, response.id);
        asset[assetApprovedKey] = isAssetApproved;
        lodash.set(signedUrl, signedUrlKey, response.signedUrl);
        Vue.set(signedUrl, 'loadingStatus', response.loadingStatus);
      }
    };

    setAssetAndSignedUrl(`data.${path}`, `signedUrls.${path}`);
  },
  importStepAssetAction(state, payload) {
    let { response, stepIndex, assetIndex, section, forceApproveAsset } = payload;
    let forceUpdateImageUrl = forceApproveAsset != null;
    let isAssetApproved =
      (forceApproveAsset == true ||
        state.currentStage == 'assetAuthorizingStage' ||
        state.currentStage == 'proofReadingStage') &&
      response.id != '';
    let newPath = '';
    if (isNaN(stepIndex)) {
      newPath = section;
    } else {
      newPath = `${section}[${stepIndex}]`;
    }
    let isAssetUploadRequired = getAssetUploadRequiredStatus(state.currentStage);
    let assetKey = forceUpdateImageUrl || isAssetUploadRequired ? 'assetId' : 'brief.assetId';
    let assetSignedUrlKey =
      forceUpdateImageUrl || isAssetUploadRequired ? 'assetSignedUrl' : 'brief.assetSignedUrl';
    let assetApprovedKey = 'assetApproved';

    let instructionHash = {},
      instructionHashFromSignedUrlsObject = {};
    if (section === 'action.instructions') {
      instructionHash = state.data.action.instructions[stepIndex] || {};
      instructionHashFromSignedUrlsObject = state.signedUrls.action.instructions[stepIndex] || {};
    } else if (section == 'application.more_practice') {
      instructionHash = state.data.application.more_practice[stepIndex] || {};
      instructionHashFromSignedUrlsObject =
        state.signedUrls.application.more_practice[stepIndex] || {};
    } else if (section == 'application.homework') {
      instructionHash = state.data.application.homework || {};
      instructionHashFromSignedUrlsObject = state.signedUrls.application.homework || {};
    } else if (section == 'analysis.listen_and_read') {
      instructionHash = state.data.analysis.listen_and_read || {};
      instructionHashFromSignedUrlsObject = state.signedUrls.analysis.listen_and_read || {};
    } else if (section != '') {
      instructionHash = get(state.data, newPath);
      instructionHashFromSignedUrlsObject = get(state.signedUrls, newPath);
    } else {
      return true;
    }
    let assetType = getFileTypeFromUrl(response.signedUrl);

    if (assetType == 'video' || (instructionHash.assets && instructionHash.assets.length == 0)) {
      lodash.set(instructionHash.assets[0], assetKey, response.id);
      instructionHash.assets[0][assetApprovedKey] = isAssetApproved;
      instructionHash.assets[0].type = assetType;
      lodash.set(
        instructionHashFromSignedUrlsObject.assets[0],
        assetSignedUrlKey,
        response.signedUrl
      );
      instructionHashFromSignedUrlsObject.assets[0].loadingStatus = response.loadingStatus;
    } else {
      let asset = instructionHash.assets[assetIndex] || {};
      lodash.set(asset, assetKey, response.id);
      asset[assetApprovedKey] = isAssetApproved;
      asset.type = assetType;

      instructionHash.assets[assetIndex] = asset;

      let assetForSignedUrl = instructionHashFromSignedUrlsObject.assets[assetIndex] || {};
      lodash.set(assetForSignedUrl, assetSignedUrlKey, response.signedUrl);
      assetForSignedUrl.loadingStatus = response.loadingStatus;
      instructionHashFromSignedUrlsObject.assets[assetIndex] = assetForSignedUrl;
    }

    if (section === 'action.instructions') {
      Vue.set(state.signedUrls.action.instructions, stepIndex, instructionHashFromSignedUrlsObject);
      Vue.set(state.data.action.instructions, stepIndex, instructionHash);
    } else if (section == 'application.more_practice') {
      Vue.set(
        state.signedUrls.application.more_practice,
        stepIndex,
        instructionHashFromSignedUrlsObject
      );
      Vue.set(state.data.application.more_practice, stepIndex, instructionHash);
    } else if (section == 'application.homework') {
      state.data.application.homework = instructionHash;
      state.signedUrls.application.homework = instructionHashFromSignedUrlsObject;
    } else if (section == 'analysis.listen_and_read') {
      state.data.analysis.listen_and_read = instructionHash;
      state.signedUrls.analysis.listen_and_read = instructionHashFromSignedUrlsObject;
    } else {
      if (isNaN(stepIndex)) {
        Object.assign(get(state.signedUrls, section), instructionHashFromSignedUrlsObject);
        Object.assign(get(state.data, section), instructionHash);
      } else {
        Vue.set(get(state.signedUrls, section), stepIndex, instructionHashFromSignedUrlsObject);
        Vue.set(get(state.data, section), stepIndex, instructionHash);
      }
    }
  },
  setDefaultData(state) {
    Object.assign(state, defaultState());
  },
  setImageUploadStatus(state, payload) {
    let { assetType, status, index } = payload;
    switch (assetType) {
      case AssetTypes.IMPORT_ASSET_TYPE:
        state.uploading_status.assets = status;
        break;
      case AssetTypes.COVER_IMAGE_ASSET_TYPE:
        state.uploading_status.cover_image = status;
        break;
      case AssetTypes.CONTENT_BOOK_ASSET_TYPE:
        state.uploading_status.classwork = status;
        break;
      case AssetTypes.STUDENT_WORK_BOOK_ASSET_TYPE:
        state.uploading_status.student_workbook = status;
        state.uploading_status.classwork = status;
        break;
      case AssetTypes.STUDENT_WORK_BOOK_ANSWER_ASSET_TYPE:
        state.uploading_status.student_workbook_answer = status;
        break;
      case AssetTypes.BLACK_BOARD_ASSET_TYPE:
        state.uploading_status.boardwork = status;
        break;
      case AssetTypes.HOMEWORK_QUESTION_ASSET_TYPE:
        state.uploading_status.homework = status;
        break;
      case AssetTypes.HOMEWORK_ANSWER_ASSET_TYPE:
        state.uploading_status.homework_answer = status;
        break;
      case AssetTypes.ACTION_VIDEO_ASSET_TYPE:
        state.uploading_status.video = status;
        break;
      case AssetTypes.ACTIVITY_EXPLANATION_ASSET_TYPE:
        state.uploading_status.activity_explanation = status;
        break;
      case AssetTypes.GENERALIZED_EXPLANATION_ASSET_TYPE:
        state.uploading_status.generalized_explanation = status;
        break;
      case AssetTypes.HIGHER_ORDER_THINKING_EXPLANATION_ASSET_TYPE:
        state.uploading_status.higher_order_thinking_explanation = status;
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_QUESTION_IMAGE_ASSET:
        state.uploading_status[
          `analysis.questions_to_guide_discussion.questions.${index}.question_image`
        ] = status;
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_QUESTION_EXPLANATION_IMAGE_ASSET:
        state.uploading_status[
          `analysis.questions_to_guide_discussion.questions.${index}.explanation_image`
        ] = status;
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_IMAGE_ASSET:
        state.uploading_status['question'] = status;
        break;
      case AssetTypes.DISCUSSION_ANALYSIS_INSTRUCTION_QUESTION_ANSWER_IMAGE_ASSET:
        state.uploading_status['question_answer'] = status;
        break;
      default:
        Vue.set(state.uploading_status, assetType, status);
        break;
    }
  },
  moveCurrentLessonPlanToNextStage(state, { currentStage }) {
    state.currentStage = currentStage;
  },
  reSetLessonPlanFormState(state) {
    Object.assign(state, defaultState());
  },
  setLessonPlanLoadingStatus(state, { status, message }) {
    if (status) state.lessonPlanLoadingStatus.status = status;
    if (message) state.lessonPlanLoadingStatus.message = message;
  },
  setLessonPlanDataFetchStatus(state, status) {
    state.lessonPlanDataFetchStatus = status;
  },
  triggerSaveLessonPlan(state, payload) {
    state.saveLessonPlanTriggerState = payload ? payload.flag : !state.saveLessonPlanTriggerState;
  },
  addEquationEditorJsonArray(state, payload) {
    let equationEditorJson = state.equationEditorJsonArray.find(
      (obj) => obj.latex == payload.latex
    );
    if (!equationEditorJson) state.equationEditorJsonArray.push(payload);
  },
  addAnalysisSubStep(state, payload) {
    if (state.data.analysis[payload.key].instructions[payload.stepIndex].children == undefined) {
      Vue.set(state.data.analysis[payload.key].instructions[payload.stepIndex], 'children', []);
    }
    state.data.analysis[payload.key].instructions[payload.stepIndex].children.push({
      description: '',
    });
    console.log(state.data.analysis);
  },
  APPROVE_LESSON_PLAN_ASSET(state, identifier) {
    let assetIdentifier = identifier.replace('data.', '');
    let path = assetIdentifier;

    const updateKeySplitIndex = path.lastIndexOf('.');
    let firstPath = path.substr(0, updateKeySplitIndex);
    let lastPath = 'assetApproved';
    if (firstPath) {
      Vue.set(lodash.get(state.data, firstPath), lastPath, true);
    } else {
      Vue.set(state.data, lastPath, true);
    }
  },
  TOGGLE_ACTION_INSTRUCTION_TEACHER_TIP_FIELD(state, payload) {
    if (payload) {
      let { stepIndex } = payload;
      let instructionData = state.data.action.instructions[stepIndex];
      let hasTeacherTip = Object.keys(instructionData).includes('teacher_tip');

      if (hasTeacherTip) {
        Vue.delete(state.data.action.instructions[stepIndex], 'teacher_tip');
      } else {
        Vue.set(state.data.action.instructions[stepIndex], 'teacher_tip', '');
      }
    }
  },

  TOGGLE_ANALYSIS_DISCUSSION_INSTRUCTION_TEACHER_TIP_FIELD(state, payload) {
    if (payload) {
      let { stepIndex } = payload;
      let instructionData = state.data.analysis.discussion.instructions[stepIndex];
      let hasTeacherTip = Object.keys(instructionData).includes('teacher_tip');

      if (hasTeacherTip) {
        Vue.delete(state.data.analysis.discussion.instructions[stepIndex], 'teacher_tip');
      } else {
        Vue.set(state.data.analysis.discussion.instructions[stepIndex], 'teacher_tip', '');
      }
    }
  },
  TOGGLE_INSTRUCTION_TEACHER_TIP_FIELD_BASED_ON_PATH(state, payload) {
    if (payload) {
      let { stepIndex, path } = payload;
      const instructionsPath = parsePath(`${path}.${stepIndex}`);
      let instructionData = getNestedObject(state.data, instructionsPath);
      let hasTeacherTip = Object.keys(instructionData).includes('teacher_tip');

      if (hasTeacherTip) {
        Vue.delete(instructionData, 'teacher_tip');
      } else {
        Vue.set(instructionData, 'teacher_tip', '');
      }
    }
  },
  TOGGLE_PHONICS_ANALYSIS_DISCUSSION_INSTRUCTION_TEACHER_TIP_FIELD(state, payload) {
    if (payload) {
      let { stepIndex, sectionKey } = payload;

      let copyData = state.data.analysis[sectionKey].instructions[stepIndex];
      let temp = Object.keys(copyData).includes('teacher_tip');

      if (temp) {
        delete copyData.teacher_tip;
      } else {
        copyData.teacher_tip = '';
      }

      Vue.set(state.data.analysis[sectionKey].instructions, stepIndex, copyData);
    }
  },
  TOGGLE_ANALYSIS_PHONICS_INSTRUCTION_TEACHER_TIP_FIELD(state, payload) {
    if (payload) {
      let { stepIndex, analysisSectionKey } = payload;

      let copyData = state.data.analysis[analysisSectionKey].instructions[stepIndex];
      let temp = Object.keys(copyData).includes('teacher_tip');

      if (temp) {
        delete copyData.teacher_tip;
      } else {
        copyData.teacher_tip = '';
      }

      Vue.set(state.data.analysis[analysisSectionKey].instructions, stepIndex, copyData);
    }
  },
  addQuestion(state) {
    state.data.analysis.questions_to_guide_discussion.questions.push({
      question: '',
      question_image: {
        assetId: '',
        brief: {
          text: '',
          assetId: '',
        },
      },
      explanation: '',
      explanation_image: {
        assetId: '',
        brief: {
          text: '',
          assetId: '',
        },
      },
    });
    state.signedUrls.analysis.questions_to_guide_discussion.questions.push({
      question_image: {
        assetSignedUrl: '',
        brief: {
          assetSignedUrl: '',
        },
      },
      explanation_image: {
        signedUrl: '',
        brief: {
          signedBriefUrl: '',
        },
      },
    });
  },
  deleteQuestion(state, index) {
    state.data.analysis.questions_to_guide_discussion.questions.splice(index, 1);
  },
  addSupportTip(state) {
    state.data.application.support_tips.push({
      keyword: '',
      description: '',
    });
  },
  deleteSupportTip(state, index) {
    state.data.application.support_tips.splice(index, 1);
  },
  switchComponent(state, payload) {
    let assetPath = payload.subPath
    if(payload.assetKey) {
      assetPath = `${payload.subPath}.${payload.assetKey}`
    }
    const parts = assetPath.split('.');
    const modifiedParts = parts.map(_part => {
      if(['activity_discussion', 'generalized_discussion', 'higher_order_thinking'].includes(_part)){
        return lodash.camelCase(_part);
      }
      return _part
    })

    const assetPathToUse = modifiedParts.join('.')
    const historyData = cloneDeep(get(state.data, payload.subPath, {}));
    const historyAsset = cloneDeep(get(state.signedUrls, assetPathToUse, {}));
    if (historyData) {
      if (payload.ignoreKeys.length > 0) {
        payload.ignoreKeys.forEach((key) => unset(historyData, key));
      }
      componentHistory[`${payload.subPath}.${+!payload.currentStatus}`] = historyData;
      componentHistory[`asset.${assetPathToUse}.${+!payload.currentStatus}`] = historyAsset;
    }
    let dataCopy = get(state.data, payload.subPath, {});
    if (componentHistory[`${payload.subPath}.${+payload.currentStatus}`]) {
      setNestedObject(
        state.data,
        payload.subPath,
        componentHistory[`${payload.subPath}.${+payload.currentStatus}`]
      );
      setNestedObject(
        state.signedUrls,
        assetPathToUse,
        componentHistory[`asset.${assetPathToUse}.${+payload.currentStatus}`]
      );
    } else {
      setNestedObject(state.data, payload.subPath, cloneDeep(payload.dsToSwitch));
      let assetPath = payload.subPath
      if(payload.assetKey) {
        assetPath = `${payload.subPath}.${payload.assetKey}`
      }

      unset(state.signedUrls, assetPath);
      const parts = assetPath.split('.');
      const modifiedParts = parts.map(_part => {
        if(['activity_discussion', 'generalized_discussion', 'higher_order_thinking'].includes(_part)){
          return lodash.camelCase(_part);
        }
        return _part
      })
      if(parts.includes('activity_discussion') || parts.includes('generalized_discussion') || parts.includes('higher_order_thinking')){
        unset(state.signedUrls, modifiedParts.join('.'));
      }
      componentHistory[`${payload.subPath}.${+payload.currentStatus}`] = cloneDeep(
        payload.dsToSwitch
      );
    }

    if (payload.ignoreKeys.length > 0) {
      payload.ignoreKeys.forEach((_key) => {
        setNestedObject(state.data, `${payload.subPath}.${_key}`, dataCopy[_key]);
      });
    }
  },
  updateDeepPathValue(state, payload) {
    if (payload.path && payload.subPath) {
      set(state.data, payload.path, payload.value);
      const data = get(state.data, payload.subPath);
      setNestedObject(state.data, payload.subPath, data);
    } else {
      setNestedObject(state.data, payload.subPath, payload.value);
    }
  },
  addLayoutOrder(state, payload) {
    setNestedObject(state.data, payload.path, payload.componentOrder)
  },
  updateSectionTitle(state, payload) {
    setNestedObject(state.data, payload.path, payload.title)
  }
};

export default mutations;
